import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import { Container, Row, Col } from '@bootstrap-styled/v4'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import Section from '../components/UI/Section'
import { H1, H5, P } from '../components/Typography'
import Button from '../components/Buttons'
import Icon from '../components/Icons'
import breakpoints from '../util/style/breakpoints'

const TemplateWrapper = styled.div`
  padding-top: 6.938rem;

  ${breakpoints.md`
    padding-top: 12.5rem;
  `}

  ${breakpoints.xl`
    padding-top: 17rem;
  `}

  .filter-spacing {
    margin-bottom: 2.5rem;
  }
`
const ContactCard = styled.div`
  background-color: ${(props) => props.theme.$gray};
  padding: 2rem 1.5rem;
  ${breakpoints.md`
      padding: 3rem 1.5rem;
  `};
  .contact-icon {
    ${breakpoints.md`
      display: flex;
      justify-content: center;
  `}
  }

  .contact-button {
    width: 100%;
    ${breakpoints.md`
      width: auto;

      display: inline-block;
  `};
  }
`

class Contact extends React.PureComponent {
  render() {
    const { intl } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            allSanityCompanyInfo {
              edges {
                node {
                  email
                  telephone
                }
              }
            }
          }
        `}
        render={(data) =>
          data.allSanityCompanyInfo.edges[0].node.telephone &&
          data.allSanityCompanyInfo.edges[0].node.email && (
            <Layout>
              <SEO
                title={`${intl.formatMessage({
                  id: 'site_title',
                })} - ${intl.formatMessage({ id: 'CONTACT_US_TITLE' })}`}
              />
              <TemplateWrapper>
                <Section>
                  <Container>
                    <Row className="justify-content-md-center mb-3">
                      <Col xs={12} md={8}>
                        <H1 className="text-md-center">
                          {intl.formatMessage({ id: 'CONTACT_US_TITLE' })}
                        </H1>
                      </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                      <Col xs={12} md={10}>
                        <P className="text-md-center">
                          {intl.formatMessage({ id: 'CONTACT_US_SUBTITLE' })}
                        </P>
                      </Col>
                    </Row>
                  </Container>
                </Section>
                <Section className="d-flex flex-wrap">
                  <Container className="mb-4">
                    <Row>
                      <Col xs={12} md={6} className="mb-4 mb-md-0">
                        <ContactCard>
                          <Row>
                            <Col>
                              <div className="contact-icon mb-3">
                                <Icon name="mail-illustration" />
                              </div>
                              <H5 className="font-weight-medium text-md-center">
                                E-mail
                              </H5>
                              <P className="text-md-center mb-md-5">
                                {intl.formatMessage({ id: 'RECEIVE_REACTION' })}
                              </P>
                              <div className="d-flex justify-content-md-center">
                                <Button
                                  name="primary-inverse"
                                  className="contact-button"
                                  type="link"
                                  color="#b98752"
                                  to={`mailto:${data.allSanityCompanyInfo.edges[0].node.email}`}
                                  from="contact"
                                >
                                  {
                                    data.allSanityCompanyInfo.edges[0].node
                                      .email
                                  }
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </ContactCard>
                      </Col>
                      <Col xs={12} md={6}>
                        <ContactCard>
                          <Row>
                            <Col>
                              <div className="contact-icon mb-3">
                                <Icon
                                  name="phone-illustration"
                                  className="contact-icon mb-3"
                                />
                              </div>

                              <H5 className="font-weight-medium text-md-center">
                                {intl.formatMessage({ id: 'CALL' })}
                              </H5>
                              <P className="text-md-center mb-md-5">
                                {intl.formatMessage({ id: 'MONDAY_FRIDAY' })}
                              </P>
                              <div className="d-flex justify-content-md-center">
                                <Button
                                  name="primary-inverse"
                                  className="contact-button"
                                  type="link"
                                  color="#b98752"
                                  to={`tel:${data.allSanityCompanyInfo.edges[0].node.telephone}`}
                                  from="contact"
                                >
                                  {
                                    data.allSanityCompanyInfo.edges[0].node
                                      .telephone
                                  }
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </ContactCard>
                      </Col>
                    </Row>
                  </Container>
                </Section>
                <Section>
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        <P color="#888888" className="text-md-right">
                          KvK nummer: 75357750{' '}
                        </P>
                      </Col>
                      <Col xs={12} md={6}>
                        <P color="#888888" className="text-md-left">
                          BTW nummer: NL002218984B62{' '}
                        </P>
                      </Col>
                    </Row>
                  </Container>
                </Section>
                <Footer withSkyline />
              </TemplateWrapper>
            </Layout>
          )
        }
      />
    )
  }
}

export default injectIntl(Contact)
